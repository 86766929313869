
import * as aroraProviderRuntime$MI2LAIRlBHVOCouX6NIRug4W7dMkIqT3_45ccVHMa7JLU from 'D:/deploy/UploadHidden/20250327-P903.312/arora-front/satellite/build-rest-2cc6d0d0-7876-41a2-a5e6-04006201c3f4/providers/aroraProvider.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "aroraProvider",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['aroraProvider']: { provider: aroraProviderRuntime$MI2LAIRlBHVOCouX6NIRug4W7dMkIqT3_45ccVHMa7JLU, defaults: {} }
}
        